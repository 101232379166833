var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-agencies-change-balance",
      "title": _vm.$t('agency.changeLimit'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "header-bg-variant": "light-info",
      "centered": "",
      "size": "xl",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.showHandle,
      "hide": _vm.hideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('div', {
          staticClass: "w-100 text-center"
        }, [_c('b-button', {
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient border-right-0 ml-2",
          attrs: {
            "pill": "",
            "disabled": _vm.hasOverWarningLimit || _vm.hasMaximumCredit || !_vm.isChangeLimitBalance
          },
          on: {
            "click": _vm.submitChangeBalanceHandle
          }
        }, [_c('div', {
          staticClass: "d-flex-center px-25"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.confirmChange')) + " ")])])], 1)];
      }
    }])
  }, [_c('b-card', {
    attrs: {
      "header-bg-variant": "warning",
      "header-class": "px-1 py-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-dark font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.note')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    staticClass: "mt-1 mb-0"
  }, [_c('div', {
    staticClass: "d-flex-center flex-column flex-md-row gap-2"
  }, [_c('b-form-textarea', {
    attrs: {
      "lazy-formatter": "",
      "formatter": _vm.trimInput,
      "disabled": _vm.isDisabledModifyNote,
      "rows": "2",
      "max-rows": "6",
      "placeholder": _vm.$t('reservation.notePlaceholder')
    },
    model: {
      value: _vm.noteToEdit,
      callback: function callback($$v) {
        _vm.noteToEdit = $$v;
      },
      expression: "noteToEdit"
    }
  }), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": _vm.isDisabledModifyNote ? 'flat-info' : 'flat-danger'
    },
    on: {
      "click": function click() {
        return _vm.isDisabledModifyNote = !_vm.isDisabledModifyNote;
      }
    }
  }, [_vm.isDisabledModifyNote ? _c('feather-icon', {
    staticClass: "m-50",
    attrs: {
      "icon": "EditIcon",
      "size": "20"
    }
  }) : _c('feather-icon', {
    staticClass: "m-50",
    attrs: {
      "icon": "XIcon",
      "size": "20"
    }
  })], 1)], 1)])], 1), _c('b-card', {
    attrs: {
      "header-bg-variant": "warning",
      "header-class": "px-1 py-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-dark font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.inputLimit')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "mx-0 mt-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "agency-limit-credit",
      "label": _vm.$t('agency.labelLimitCredit')
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('agency.labelLimitCredit')))]), _vm.isRoleF2 && _vm.meData ? _c('p', {
          staticClass: "d-inline mb-0"
        }, [_vm._v(" ("), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("Tối đa: " + _vm._s("< ".concat(_vm.formatCurrency(_vm.meData.agency.limitCredit))))]), _vm._v(") ")]) : _vm._e()])];
      },
      proxy: true
    }])
  }, [_c('IAmInputMoney', {
    staticClass: "flex-grow-1",
    attrs: {
      "value-money": _vm.agenciesDataToEdit.limitCredit
    },
    on: {
      "update:valueMoney": function updateValueMoney($event) {
        return _vm.$set(_vm.agenciesDataToEdit, "limitCredit", $event);
      },
      "update:value-money": function updateValueMoney($event) {
        return _vm.$set(_vm.agenciesDataToEdit, "limitCredit", $event);
      }
    }
  })], 1)], 1)], 1)], 1), _c('AppCollapse', {
    attrs: {
      "type": "border card"
    }
  }, [_c('AppCollapseItem', {
    attrs: {
      "title": "",
      "class-header": "card-header bg-warning"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-dark font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.auditLog')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-table-lite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "sticky-header": true,
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "thead-class": "text-dark font-weight-bolder text-nowrap",
      "items": _vm.agenciesDataToEdit.auditLogsCredit,
      "fields": _vm.tableColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column,
            staticClass: "text-dark"
          }, [_vm._v(" " + _vm._s(_vm.$t("agency.auditLogsCredit.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.agenciesDataToEdit.auditLogsCredit.length - Number(data.index)) + " ")];
      }
    }, {
      key: "cell(updated)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.updatedAt).dateTime) + " "), _c('div', [_vm._v(" " + _vm._s(_vm.$t('by')) + " "), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(item.modifiedBy.username))])])])];
      }
    }, {
      key: "cell(limitCredit)",
      fn: function fn(_ref3) {
        var _JSON$parse$limitCred, _JSON$parse$limitCred2;
        var item = _ref3.item;
        return [_c('div', [_c('div', [_c('span', {
          staticClass: "text-body-3"
        }, [_c('em', [_vm._v("Trước khi sửa đổi:")])]), _c('div', {
          staticClass: "font-weight-bold text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_JSON$parse$limitCred = JSON.parse(item.oldValue).limitCredit) !== null && _JSON$parse$limitCred !== void 0 ? _JSON$parse$limitCred : JSON.parse(item.oldValue))) + " ")])]), _c('div', [_c('span', {
          staticClass: "text-body-3"
        }, [_c('em', [_vm._v("Sau khi sửa đổi:")])]), _c('div', {
          staticClass: "font-weight-bold text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_JSON$parse$limitCred2 = JSON.parse(item.newValue).limitCredit) !== null && _JSON$parse$limitCred2 !== void 0 ? _JSON$parse$limitCred2 : JSON.parse(item.newValue))) + " ")])])])];
      }
    }, {
      key: "cell(warningCredit)",
      fn: function fn(_ref4) {
        var _JSON$parse$warningCr, _JSON$parse$warningCr2;
        var item = _ref4.item;
        return [_c('div', [_c('div', [_c('span', {
          staticClass: "text-body-3"
        }, [_c('em', [_vm._v("Trước khi sửa đổi:")])]), _c('div', {
          staticClass: "font-weight-bold text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_JSON$parse$warningCr = JSON.parse(item.oldValue).warningCredit) !== null && _JSON$parse$warningCr !== void 0 ? _JSON$parse$warningCr : JSON.parse(item.oldValue))) + " ")])]), _c('div', [_c('span', {
          staticClass: "text-body-3"
        }, [_c('em', [_vm._v("Sau khi sửa đổi:")])]), _c('div', {
          staticClass: "font-weight-bold text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_JSON$parse$warningCr2 = JSON.parse(item.newValue).warningCredit) !== null && _JSON$parse$warningCr2 !== void 0 ? _JSON$parse$warningCr2 : JSON.parse(item.newValue))) + " ")])])])];
      }
    }, {
      key: "cell(note)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticStyle: {
            "min-width": "140px"
          }
        }, [_c('div', [_c('span', {
          staticClass: "text-body-3"
        }, [_c('em', [_vm._v("Trước khi sửa đổi:")])]), _c('div', {
          staticClass: "font-weight-bold "
        }, [_vm._v(" " + _vm._s(JSON.parse(item.oldValue).note || 'N/A') + " ")])]), _c('div', [_c('span', {
          staticClass: "text-body-3"
        }, [_c('em', [_vm._v("Sau khi sửa đổi:")])]), _c('div', {
          staticClass: "font-weight-bold "
        }, [_vm._v(" " + _vm._s(JSON.parse(item.newValue).note || 'N/A') + " ")])])])];
      }
    }], null, true)
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }